export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: "firstName",
    sortable: true,
    sortField: "firstName",
    label: "field.firstName",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "lastName",
    sortable: true,
    sortField: "lastName",
    label: "field.lastName",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "email",
    sortable: true,
    sortField: "email",
    label: "field.email",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "phoneNumber",
    sortable: true,
    sortField: "phoneNumber",
    label: "field.phoneNumber",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "telegram",
    label: "field.telegram",
  },
  {
    key: "companyNameEn",
    sortable: true,
    sortField: "companyId",
    label: "field.companyNameEn",
  },
  {
    key: "companyNameKm",
    sortable: true,
    sortField: "companyId",
    label: "field.companyNameKm",
  },
  {
    key: "departmentNameEn",
    sortable: true,
    sortField: "departmentId",
    label: "field.departmentNameEn",
  },
  {
    key: "departmentNameKm",
    sortable: true,
    sortField: "departmentId",
    label: "field.departmentNameKm",
  },
  {
    key: "jobNameEn",
    sortable: true,
    sortField: "jobId",
    label: "field.jobNameEn",
  },
  {
    key: "jobNameKm",
    sortable: true,
    sortField: "jobId",
    label: "field.jobNameKm",
  },
  {
    key: "sourceNameEn",
    sortable: true,
    sortField: "sourceId",
    label: "field.sourceNameEn",
  },
  {
    key: "sourceNameKm",
    sortable: true,
    sortField: "sourceId",
    label: "field.sourceNameKm",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "id",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
